<template>
    <div>
        <div class="mobile-disclosure">
            <header>
                <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
            </header>
            <crumbs :item="title"></crumbs>
            <div class="main-content">
                <div class="price">
                    <main>
                        <p>安联投资连结保险账户每日价格公告</p>
                        <div class="tab">
                            <div class="account">
                                <van-cell-group>
                                    <van-field
                                        v-model="actionTypeFlag.value.text"
                                        label="产品名称："
                                        right-icon="arrow"
                                        placeholder="选择产品名称"
                                        readonly
                                        @click="showSelectPicker(actionTypeFlag)"
                                    />
                                </van-cell-group>
                            </div>
                            <div class="pricingDay">计价日:&ensp;&ensp;{{ pricingDay }}</div>
                            <div class="content">
                                <vxe-table
                                class="mytable-scrollbar"
                                    stripe
                                    border
                                    round
                                    ref="xTable"
                                    max-height="400"
                                    :loading="loading"
                                    :row-config="{ isHover: true }"
                                    :data="tableData"
                                >
                                    <vxe-column type="seq" width="60"></vxe-column>
                                    <vxe-column field="name" title="账户"></vxe-column>
                                    <vxe-column
                                        field="num"
                                        title="投资单位价值"
                                        sort-type="number"
                                        sortable
                                    ></vxe-column>
                                </vxe-table>
                            </div>
                        </div>
                        <div class="view_btn">
                            <div class="btn">
                                <a @click="details()">
                                    <p>历史价格公告</p>
                                </a>
                                <a
                                    target="_blank"
                                    href="https://www.allianz.com.cn/allianz/9ff2da1421374427916a893c10387c65.pdf"
                                >
                                    <p>查看账户说明</p>
                                </a>
                            </div>
                        </div>
                    </main>
                </div>
                <div class="report">
                    <div class="report_main">
                        <p>安联投资连结保险账户月度报告</p>
                        <ul>
                            <li v-for="(item, index) in report" :key="index">
                                <img src="~@/static/images/right.png" alt="" />
                                <a :href="item.fileUrl" target="_blank">{{ item.fileTitle }}</a>
                            </li>
                        </ul>
                        <div class="paging">
                            <p>共有{{ total }}条记录</p>
                            <div>
                                <img
                                    @click="getPageNumAReduction()"
                                    src="~@/static/images/l_arrow@2x.png"
                                    alt=""
                                />
                                <span>{{ pageNum }}/{{ pages }}</span>
                                <img
                                    @click="getPageNumAdd()"
                                    src="~@/static/images/ic_arrow_black@2x.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <!--        <div class="view_btn">-->
                        <!--          <div class="btn">-->
                        <!--            <p>更多月度报告</p>-->
                        <!--          </div>-->
                        <!--        </div>-->
                    </div>
                </div>
                <div class="login_box">
                    <div class="login">
                        <div>
                            <a
                                target="_blank"
                                href="https://pts.allianz.com.cn/azclportal/faces/cplogin"
                            >
                                <p>账户登录</p>
                                <img
                                    src="~@/static/images/secondaryMenu/news/youjiantou@2x.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div>
                            * 登录客户专区，可以查询到本人投资连结保险保单的相关信息，详情包括保费
                            内容、缴费记录、投保人信息、投资账户单位数、投资账户价值、每月扣除额等。
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="qrcode">
                <div class="item">
                    <img src="~@/static/images/public.jpg" alt="" />
                    <span>公众号：安联人寿</span>
                </div>
                <div class="item">
                    <img src="~@/static/images/service.jpg" alt="" />
                    <span>服务号-安联在线</span>
                </div>
                </div>
                <div class="info">
                <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
                <p class="beian">沪ICP备07011309号-1</p>
                </div>
            </div>
        </div>
        <!-- 选择 -->
        <selectPicker ref="selectPicker" @click="updataSelectPicker"></selectPicker>
    </div>
</template>

<script>
    import selectPicker from '@/components/selectPicker.vue'
	import crumbs from "@/components/crumbs.vue";
    import { getFileList } from "@/api/public";
	import {
		getNewDisclosureTable,
		getNewDisclosureOption,
	} from "@/api/disclosure";
	export default {
		name: "disclosureMobile",
        props: {
        },
		data() {
			return {
				isMobile:false,
				title: [
					{
						title: "新闻公告",
						link: "",
					},
					{
						title: "投连险信息披露",
						link: "/disclosure",
					},
				],
				report: [],
				iframeWin: {},
				url: "",
				// url: "https://az-uat.chengstarkj.com/home",
				value: "",
				total: 0,
				pageNum: 0,
				pages: 0,
                actionTypeFlag:{//选择信息
                    value: {
                        value: "",
                        text: ""
                    },
                    data: [
                    ]
                },
				pricingDay: "", //计价日
				tableData: [], //表格值
				loading: false, //表格加载中
			};
		},
		components: {
			crumbs,
			selectPicker,
		},
		methods: {
			//跳转
			details() {
				if (!this.actionTypeFlag.value.value) {
					this.$message.warning("请先选择产品!");
					return;
				}
				this.$router.push({
					name: "disclosureDetails",
					params: {
						countType: this.actionTypeFlag.value.value,
					},
				});
				localStorage.setItem("disclosureCovercode", this.actionTypeFlag.value.value);
			},
			getPageNumAdd() {
				if (this.pageNum < this.pages) {
					this.pageNum = this.pageNum + 1;
					this.request(this.pageNum);
				} else {
					this.$message.warning("已经是最后一页了");
					console.log("no");
				}
			},
			// 上一页
			getPageNumAReduction() {
				if (this.pageNum <= 1) {
					this.$message.warning("已经是第一页了");
				} else {
					this.pageNum = this.pageNum - 1;
					this.request(this.pageNum);
				}
			},
			async request(num) {
				try {
					const [timeStamp, sign] = this.$getSign();
					const res = await getFileList({
						timeStamp,
						sign,
						fileCategory: "AZ01505",
						pageNum: num,
						// fileTitle: title,
					});
					if (res.data.code == 200) {
						console.log(res.data);
						this.report = res.data.rows;
						this.pageNum = res.data.pageNum;
						this.pages = res.data.pages;
						this.total = res.data.total;
					}
					if (
						res.data.code == 401 ||
						res.data.code == 404 ||
						res.data.code == 500
					) {
						this.$router.replace("/found404");
					}
				} catch (error) {
					// this.$message.error(error);
					console.log(error);
				}
			},

			//选择框改变触发
			onChangeCountType() {
				this.getNewDisclosureTable(this.actionTypeFlag.value.value);
			},

			//获取选项数据
			async getNewDisclosureOption() {
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { rows },
				} = await getNewDisclosureOption({ timeStamp, sign });
				var action_option = rows.map((item) => ({
					value: item.COVER_CODE,
					text: item.FULL_NAME,
				}));
                this.actionTypeFlag.data = action_option || []
                this.actionTypeFlag.value = this.actionTypeFlag.data[0]
				this.getNewDisclosureTable(this.actionTypeFlag.value.value); //默认下拉值对应的表格数据
			},

			//获取表格数据
			async getNewDisclosureTable(coverCode) {
				this.loading = true; //加载
				const [timeStamp, sign] = this.$getSign();
				const {
					data: { rows },
				} = await getNewDisclosureTable({ timeStamp, sign, coverCode });
				this.pricingDay = rows[0].VALUED_UPTO.substring(0, 10); //计价日
				this.tableData = rows.map((item) => ({
					//数据
					id: item.FUND_ID,
					name: item.FUND_FULL_NAME,
					num: item.BID_PRICE ? item.BID_PRICE.toFixed(4) : ""
				}));
				this.loading = false; //加载
			},
            showSelectPicker(params){
                console.log(params, 'showSelectPickershowSelectPicker');
                this.$refs.selectPicker.parentMsg({ struct: { ...params, type: 'selectPickerFlag' } });
            },
            updataSelectPicker(params) {
                console.log(params, 'paramsparams');
                this.actionTypeFlag.value = params.struct.value
                this.onChangeCountType()
            },
		},
		created() {
			this.request();
			this.getNewDisclosureOption(); //获取下拉选项
		},
		mounted() {
			this.url =
				"https://www.allianz.com.cn/ProductValueQuery/faces/coverPriceCurrent.jspx";
			// this.url="https://az-uat.chengstarkj.com"
		},
	};
</script>
<style lang='scss' scoped>
.mytable-scrollbar ::-webkit-scrollbar {
	width: 5px ;
  } 
    @function px2rem($px, $screen: 1920) {
        @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
    }

    @function px2vw($px) {
        @return calc(100vw * #{$px} / 1920)
    }
    li {
		list-style: none;
	}

	a {
		text-decoration: none;
		color: #000;
	}
    .mobile-disclosure {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-color: #fff;
        height: 100vh;
        width: 100vw;
        padding-bottom: px2rem(30, 375);

        header {
            width: 100%;
            height: px2rem(50, 375);
            display: flex;
            align-items: center;
            padding-left: px2rem(15, 375);
            img {
                width: px2rem(75, 375);
                height: px2rem(30, 375);
            }
        }
        .main-content {
            width: 100%;
            padding: px2rem(10, 375) px2rem(10, 375);
            padding-bottom: px2rem(120, 375);
            height: 100%;
            overflow-y: scroll;
            .price {
                width: 100%;

                > main {
                    width: 100%;
                    // height: 26vw;
                    margin: auto;

                    > p {
                        text-align: center;
                        font-size: px2rem(16, 375);
                        margin: px2rem(10, 375) 0;
                        color: #003781;
                    }

                    > .tab {
                        //width: 60%;
                        /* width: 106rem; */
                        background: #edeff5;
                        margin: auto;
                        /* padding: 0 5rem 5rem; */

                        .account {
                            background: #edeff5;
                            padding: px2rem(10, 375) px2rem(10, 375) px2rem(10, 375) px2rem(10, 375);
                            font-size: px2rem(16, 375);
                            /deep/ .van-field__label {
                                width: unset;
                            }
                            /deep/ .van-field__control {
                                font-size: calc(100vw / (375 / 10) * 12 / 10);
                            }
                            /deep/ .van-cell {
                                padding: px2rem(6, 375) px2rem(12, 375);
                                font-size: px2rem(12, 375);
                            }
                        }

                        .pricingDay {
                            padding:  px2rem(10, 375)  px2rem(10, 375)  px2rem(10, 375)  px2rem(10, 375);
                            font-size:  px2rem(16, 375);
                        }
                    }
                }
            }

            .view_btn {
                > .btn {
                    font-size: px2rem(16, 375);
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    width: 80%;
                    margin: auto;
                    justify-content: space-between;

                    > a {
                        color: #fff;

                        > p {
                            padding: px2rem(3, 375) px2rem(10, 375);;
                            background-color: #003781;
                            margin: px2rem(10, 375) px2rem(1, 375);
                            cursor: pointer;
                        }
                    }
                }
            }

            .report {
                width: 100%;

                .report_main {
                    border-top: 1px solid;

                    width: 95%;
                    margin: auto;

                    > p {
                        font-size: px2rem(16, 375);
                        margin: px2rem(10, 375);
                        text-align: center;
                    }

                    > ul {
                        margin-bottom: px2rem(10, 375);

                        > li {
                            display: flex;
                            align-items: center;
                            font-size: px2rem(16, 375);
                            height: px2rem(20, 375);
                            line-height: px2rem(20, 375);
                            color: #004b98;
                            cursor: pointer;

                            > img {
                                width: px2rem(15, 375);
                                height: px2rem(10, 375);
                                margin-right: px2rem(10, 375);
                            }

						> a {
							text-decoration: none;
							color: #003781;
							font-size: px2rem(10, 375);
						}
					}
				}
			}
		}

		.login_box {
			width: 100%;

			.login {
				margin: px2rem(10, 375) auto px2rem(10, 375);
				background: url("~@/static/images/secondaryMenu/news/bg_kehu_login@2x.png");
				background-size: 100% 100%;
				color: #fff;
				padding: px2rem(10, 375) px2rem(10, 375);
				box-sizing: border-box;

				> div:first-child {
					font-size: px2rem(18, 375);
					display: flex;
					align-items: center;
					cursor: pointer;

					> a {
						color: #fff;
						display: flex;
						align-items: center;

						> img {
							width: px2rem(20, 375);
							height: px2rem(17, 375);
							margin-left: px2rem(5, 375);
						}
					}
				}

				> div:last-child {
					font-size: px2rem(12, 375);
					width: 75%;
					margin-top: px2rem(12, 375);
				}
			}
		}

		::v-deep .paging {
			width: 100%;
			padding: 0;
			margin: 2rem 0;
		}
        }
        .bottom {
            width: 100%;
            background-color: #fff;
            padding: px2rem(10, 375) 0;
            position: absolute;
            bottom: 0;
            z-index: 9999;
            .qrcode {
                display: flex;
                justify-content: space-evenly;
                margin: px2rem(30, 375) 0;
                display: none;

            .item {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                img {
                    width: px2rem(100, 375);
                    height: px2rem(100, 375);
                }
                span {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    }
                }
            }

            .info {
                p {
                    font-size: px2rem(13, 375);
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: center;

                    &.beian {
                        color: RGBA(38, 77, 139, 1);
                    }
                }
            }
        }
    }
</style>